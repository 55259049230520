import React, { useRef } from "react"
import { Link, graphql } from "gatsby"

import { labels, localeUrls } from '../langs/langs'
import { storesIcons } from '../const/icons'

import { getKeyByValue } from "../functions/functions";

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StoreCard } from "../components/StoresSlider";
import PartnersSlider from "../components/PartnersSlider";
import ArrowLink from "../components/ArrowLink";


const StoreDetailTemplate = ({ location, data, pageContext: { localeID, key, secKey, category } }) => {

    const stores = data.allStores.nodes;

    const emptyMsg = <div><h3 className="empty">{labels[localeID]["WEARESORRY"]}</h3><p className="empty">{labels[localeID]["NOSTORES"]}</p></div>;

    const translateUrlKey = getKeyByValue(localeUrls[localeID], category);
    const urlTranslations = {
        "en_US": localeUrls["en_US"]["STORES"] + '/' + localeUrls["en_US"][translateUrlKey],
        "sk_SK": localeUrls["sk_SK"]["STORES"] + '/' + localeUrls["sk_SK"][translateUrlKey],
    }

    return (
        <Layout location={location} localeID={localeID} translatedSlug={urlTranslations}>
            <Seo title={labels[localeID][key] + " | " + labels[localeID]["STORES"]} description={labels[localeID][key] + " | " + labels[localeID]["STORES"]} />
            <div className="breadCrumbs">
                <div className="content center">
                    <ArrowLink to={localeUrls[localeID]["STORES"] + "#" + localeUrls[localeID][key]} left={true}>{labels[localeID]["BACK"]}</ArrowLink>
                    <div className="fillFlex"/>
                    <Link to={localeUrls[localeID]["STORES"] + "#" + localeUrls[localeID][secKey]}>{labels[localeID][secKey]}</Link>
                    <div>/</div>
                    <Link to={localeUrls[localeID]["STORES"] + "/" + localeUrls[localeID][key]}>{labels[localeID][key]}</Link>
                </div>
            </div>
            <div className="hPadSec sm storeArchive">
                <div className="content">
                    <div className="center">
                        <div className="hCon center l s"><img src={storesIcons[key]}/><h1 className="center l b">{labels[localeID][key]}</h1></div>
                        <div className="fillFlex"></div>
                    </div>
                </div>
            </div>
            <div className="cardCon pb">
                <div className="content">
                    {!!stores.length ? stores.map((item, index)=>{
                            return <StoreCard key={index} item={item} localeID={localeID}/>
                        }) : emptyMsg}
                </div>
            </div>
            <PartnersSlider className="gray" localeID={localeID}/>
        </Layout>
    )
}

export default StoreDetailTemplate

export const pageQuery = graphql`
    query StoreArchiveQuery(
        $category: String!
        $localeID: String!
    ) {
        allStores: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: $category}}}}, locale: {locale: {eq: $localeID}}}
        ) {
            ...StoreSliderData
        }
    }
`
